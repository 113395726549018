

import { useState } from 'react'

import { ChevronDown, ChevronUp, Plus, Search, MoreHorizontal, Pencil, Trash, Loader } from 'lucide-react'
import Swal from 'sweetalert2'
import { Button } from "../../components/ui/button"
import { Input } from "../../components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../../components/ui/dialog"
import { Label } from "../../components/ui/label"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card"
import Image from '../../components/Image'
import { useToast } from '../../hooks/use-toast'

const baseUrl = 'https://api.example.com'

interface Market {
  id: string;
  image: string;
  nom: string;
  pays: string;
  latitude: number;
  longitude: number;
  adresse: string;
}

const ITEMS_PER_PAGE = 10;



export default function MarketTable() {
  // const { toast } = useToast()

  const [markets, setMarkets] = useState<Market[]>([
    {
      id: "9b4f68c8-922a-4243-92c2-8085217466ee",
      image: "https://prodapi.odon-afrik.com/storage/market/1707653626.jpg",
      nom: "Assigamé",
      pays: "Togo",
      latitude: 6.1268999209018,
      longitude: 1.2303919862959,
      adresse: "Lomé adawoulato"
    },
    {
      id: "9b4f6905-4aa1-4d90-9ec6-a557d0d86f32",
      image: "https://prodapi.odon-afrik.com/storage/market/1707653666.jpg",
      nom: "Gbossimé",
      pays: "Togo",
      latitude: 6.1610792367056,
      longitude: 1.2075241690953,
      adresse: "Doumassessé"
    },
    {
      id: "9b85dd98-c6bd-4006-95c0-5c984de6548d",
      image: "https://prodapi.odon-afrik.com/storage/market/1709992507.jpg",
      nom: "Zangera",
      pays: "Togo",
      latitude: 6.1613245,
      longitude: 1.2117917,
      adresse: "Marché de Zangera"
    },
    {
      id: "9b8831c6-0545-43af-b854-f237122a5342",
      image: "https://prodapi.odon-afrik.com/storage/market/1710092529.png",
      nom: "Marché de Totsi",
      pays: "Togo",
      latitude: 6.1945219998697,
      longitude: 1.1879736929877,
      adresse: "Lomé, Totsi"
    }
  ])


  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortColumn, setSortColumn] = useState<keyof Market>('nom')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [newMarket, setNewMarket] = useState<Partial<Market>>({
    nom: '',
    pays: '',
    adresse: '',
    image: '',
    latitude: 0,
    longitude: 0
  })
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [editingMarket, setEditingMarket] = useState<Market | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSort = (column: keyof Market) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  const sortedMarkets = [...markets].sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1
    if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1
    return 0
  })

  const filteredMarkets = sortedMarkets.filter(market =>
    market.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
    market.pays.toLowerCase().includes(searchTerm.toLowerCase()) ||
    market.adresse.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const totalPages = Math.ceil(filteredMarkets.length / ITEMS_PER_PAGE)
  const paginatedMarkets = filteredMarkets.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  )

  const handleAddMarket = async () => {
    if (newMarket.nom && newMarket.pays && newMarket.adresse && newMarket.image) {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr ?',
        text: "Voulez-vous ajouter ce nouveau marché ?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, ajouter !',
        cancelButtonText: 'Annuler'
      })

      if (result.isConfirmed) {
        setIsLoading(true)
        // Simuler un appel API
        await new Promise(resolve => setTimeout(resolve, 1000))
        const marketToAdd: Market = {
          ...newMarket as Market,
          id: Date.now().toString(),
        }
        setMarkets([...markets, marketToAdd])
        setNewMarket({ nom: '', pays: '', adresse: '', image: '', latitude: 0, longitude: 0 })
        setShowAddModal(false)
        setIsLoading(false)
        Swal.fire('Ajouté !', 'Le marché a été ajouté avec succès.', 'success')
      }
    }
  }

  const handleEditMarket = (market: Market) => {
    setEditingMarket(market)
    setShowEditModal(true)
  }

  const handleUpdateMarket = async () => {
    if (editingMarket) {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr ?',
        text: "Voulez-vous modifier ce marché ?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, modifier !',
        cancelButtonText: 'Annuler'
      })

      if (result.isConfirmed) {
        setIsLoading(true)
        // Simuler un appel API
        await new Promise(resolve => setTimeout(resolve, 1000))
        const updatedMarkets = markets.map(m =>
          m.id === editingMarket.id ? editingMarket : m
        )
        setMarkets(updatedMarkets)
        setShowEditModal(false)
        setEditingMarket(null)
        setIsLoading(false)
        Swal.fire('Modifié !', 'Le marché a été modifié avec succès.', 'success')
      }
    }
  }

  const handleDeleteMarket = async (id: string) => {
    const result = await Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
      cancelButtonText: 'Annuler'
    })

    if (result.isConfirmed) {
      setIsLoading(true)
      // Simuler un appel API
      await new Promise(resolve => setTimeout(resolve, 1000))
      setMarkets(markets.filter(market => market.id !== id))
      setIsLoading(false)
      Swal.fire('Supprimé !', 'Le marché a été supprimé.', 'success')
    }
  }

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl)
  }

  return (
    <div className="container mx-auto p-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
        <h1 className="text-3xl font-semibold text-gray-800 mb-4 sm:mb-0">Marchés</h1>
        <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
          <div className="relative w-full sm:w-64">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="search"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchTerm(e.target.value)
                setCurrentPage(1)
              }}
              className="pl-8 w-full"
            />
          </div>
          <Dialog open={showAddModal} onOpenChange={setShowAddModal}>
            <DialogTrigger asChild>
              <Button className="w-full sm:w-auto">
                <Plus className="mr-2 h-4 w-4" />
                Ajouter un marché
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Ajouter un nouveau marché</DialogTitle>
                <DialogDescription>
                  Remplissez les informations pour ajouter un nouveau marché.
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="nom" className="text-right">
                    Nom
                  </Label>
                  <Input
                    id="nom"
                    value={newMarket.nom}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewMarket({ ...newMarket, nom: e.target.value })}
                    className="col-span-3"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="pays" className="text-right">
                    Pays
                  </Label>
                  <Input
                    id="pays"
                    value={newMarket.pays}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewMarket({ ...newMarket, pays: e.target.value })}
                    className="col-span-3"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="adresse" className="text-right">
                    Adresse
                  </Label>
                  <Input
                    id="adresse"
                    value={newMarket.adresse}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewMarket({ ...newMarket, adresse: e.target.value })}
                    className="col-span-3"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="image" className="text-right">
                    URL de l'image
                  </Label>
                  <Input
                    id="image"
                    value={newMarket.image}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewMarket({ ...newMarket, image: e.target.value })}
                    className="col-span-3"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="latitude" className="text-right">
                    Latitude
                  </Label>
                  <Input
                    id="latitude"
                    type="number"
                    value={newMarket.latitude}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewMarket({ ...newMarket, latitude: parseFloat(e.target.value) })}
                    className="col-span-3"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="longitude" className="text-right">
                    Longitude
                  </Label>
                  <Input
                    id="longitude"
                    type="number"
                    value={newMarket.longitude}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewMarket({ ...newMarket, longitude: parseFloat(e.target.value) })}
                    className="col-span-3"
                  />
                </div>
              </div>
              <DialogFooter>
                <Button onClick={handleAddMarket}>Ajouter</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg">
            <Loader className="h-8 w-8 animate-spin text-blue-500" />
            <p className="mt-2 text-gray-700">Chargement en cours...</p>
          </div>
        </div>
      )}

      <div className="grid gap-4 md:hidden">
        {paginatedMarkets.map((market) => (
          <Card key={market.id}>
            <CardHeader>
              <CardTitle>{market.nom}</CardTitle>
              <CardDescription>{market.pays}</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="aspect-square relative w-full h-48 mb-4">
                <Image
                  src={market.image}
                  alt={market.nom}
                  fill 
                  objectFit="cover"
                  className="rounded-md"
                  onClick={() => handleImageClick(market.image)}
                />
              </div>
              <p><strong>Adresse:</strong> {market.adresse}</p>
              <p><strong>Coordonnées:</strong> {market.latitude.toFixed(6)}, {market.longitude.toFixed(6)}</p>
            </CardContent>
            <CardFooter>
              <Button variant="outline" className="w-full" onClick={() => handleEditMarket(market)}>
                <Pencil className="mr-2 h-4 w-4" />
                Modifier
              </Button>
              <Button variant="outline" className="w-full mt-2" onClick={() => handleDeleteMarket(market.id)}>
                <Trash className="mr-2 h-4 w-4" />
                Supprimer
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>

      <div className="hidden md:block">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Image</TableHead>
              <TableHead className="cursor-pointer" onClick={() => handleSort('nom')}>
                Nom {sortColumn === 'nom' && (sortDirection === 'asc' ? <ChevronUp className="inline" /> : <ChevronDown className="inline" />)}
              </TableHead>
              <TableHead className="cursor-pointer" onClick={() => handleSort('pays')}>
                Pays {sortColumn === 'pays' && (sortDirection === 'asc' ? <ChevronUp className="inline" /> : <ChevronDown className="inline" />)}
              </TableHead>
              <TableHead>Coordonnées</TableHead>
              <TableHead className="cursor-pointer" onClick={() => handleSort('adresse')}>
                Adresse {sortColumn === 'adresse' && (sortDirection === 'asc' ? <ChevronUp className="inline" /> : <ChevronDown className="inline" />)}
              </TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedMarkets.map((market) => (
              <TableRow key={market.id}>
                <TableCell>
                  <Image
                    src={market.image}
                    alt={market.nom}
                    width={80}
                    height={80}
                    className="rounded-md object-cover cursor-pointer"
                    onClick={() => handleImageClick(market.image)}
                  />
                </TableCell>
                <TableCell className="font-medium">{market.nom}</TableCell>
                <TableCell>{market.pays}</TableCell>
                <TableCell>
                  {market.latitude.toFixed(6)}, {market.longitude.toFixed(6)}
                </TableCell>
                <TableCell>{market.adresse}</TableCell>
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" className="h-8 w-8 p-0">
                        <span className="sr-only">Ouvrir le menu</span>
                        <MoreHorizontal className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuLabel>Actions</DropdownMenuLabel>
                      <DropdownMenuItem onClick={() => handleEditMarket(market)}>
                        <Pencil className="mr-2 h-4 w-4" />
                        Modifier
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={() => handleDeleteMarket(market.id)}>
                        <Trash className="mr-2 h-4 w-4" />
                        Supprimer
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {totalPages > 1 && (
        <div className="flex flex-col sm:flex-row justify-between items-center mt-4 space-y-2 sm:space-y-0">
          <div className="text-sm text-gray-700 dark:text-gray-400">
            Affichage de {(currentPage - 1) * ITEMS_PER_PAGE + 1} à {Math.min(currentPage * ITEMS_PER_PAGE, filteredMarkets.length)} sur {filteredMarkets.length} entrées
          </div>
          <nav className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
              disabled={currentPage === 1}
            >
              Précédent
            </Button>
            <span className="text-sm font-medium">
              Page {currentPage} sur {totalPages}
            </span>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
              disabled={currentPage === totalPages}
            >
              Suivant
            </Button>
          </nav>
        </div>
      )}

      <Dialog open={showEditModal} onOpenChange={setShowEditModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Modifier le marché</DialogTitle>
            <DialogDescription>
              Modifiez les informations du marché sélectionné.
            </DialogDescription>
          </DialogHeader>
          {editingMarket && (
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="edit-nom" className="text-right">
                  Nom
                </Label>
                <Input
                  id="edit-nom"
                  value={editingMarket.nom}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditingMarket({ ...editingMarket, nom: e.target.value })}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="edit-pays" className="text-right">
                  Pays
                </Label>
                <Input
                  id="edit-pays"
                  value={editingMarket.pays}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditingMarket({ ...editingMarket, pays: e.target.value })}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="edit-adresse" className="text-right">
                  Adresse
                </Label>
                <Input
                  id="edit-adresse"
                  value={editingMarket.adresse}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditingMarket({ ...editingMarket, adresse: e.target.value })}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="edit-image" className="text-right">
                  URL de l'image
                </Label>
                <Input
                  id="edit-image"
                  value={editingMarket.image}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditingMarket({ ...editingMarket, image: e.target.value })}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="edit-latitude" className="text-right">
                  Latitude
                </Label>  
                <Input
                  id="edit-latitude"
                  type="number"
                  value={editingMarket.latitude}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditingMarket({ ...editingMarket, latitude: parseFloat(e.target.value) })}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="edit-longitude" className="text-right">
                  Longitude
                </Label>
                <Input
                  id="edit-longitude"
                  type="number"
                  value={editingMarket.longitude}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditingMarket({ ...editingMarket, longitude: parseFloat(e.target.value) })}
                  className="col-span-3"
                />
              </div>
            </div>
          )}
          <DialogFooter>
            <Button onClick={handleUpdateMarket}>Mettre à jour</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={!!selectedImage} onOpenChange={() => setSelectedImage(null)}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Image du marché</DialogTitle>
          </DialogHeader>
          {selectedImage && (
            <div className="relative aspect-video">
              <Image
                src={selectedImage}
                alt="Image du marché en grand"
                width={500}
                height={500}
                objectFit="contain"
              />
            </div>
          )}
          <DialogFooter>
            <Button onClick={() => setSelectedImage(null)}>Fermer</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}