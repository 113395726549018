import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../store';
import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;



const baseQuery = fetchBaseQuery({ baseUrl: '/api' });

const customBaseQuery = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error) {
        return result; // En cas d'erreur, on renvoie l'erreur telle quelle
    }

    // On extrait le champ `data` de la réponse
    if (result.data && result.data.data) {
        return { ...result, data: result.data.data };
    }

    return result;
};

export default customBaseQuery;
