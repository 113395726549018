import React, { useState, useEffect } from 'react';
import { LockIcon } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { apiSlice, useGetProfileQuery, useLoginMutation } from '../../store/slices/apiSlice';
import { setAuth } from '../../store/slices/authSlice';
import { LoadingScreen } from '../../components/button/LoadingScreen';
import { LoadingButton } from '../../components/button/LoadingButton';
import { useToast } from '../../hooks/use-toast';
import { setUser } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';

const AdminLogin: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [login, { isLoading: isLoginLoading }] = useLoginMutation();
    const [getProfile] = apiSlice.endpoints.getProfile.useLazyQuery();
    const [isLoading, setIsLoading] = useState(false);
    const { toast } = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();







    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            // 1. Login
            const loginResponse = await login({
                telephone: email,
                password
            }).unwrap();

            console.log(loginResponse)

            // 2. Set auth token
            dispatch(setAuth({ token: loginResponse.data.token }));

            // 3. Fetch profile
            const { data: profileResponse } = await getProfile();

            if (!profileResponse) {
                throw new Error('Impossible de récupérer le profil');
            }

            console.log(profileResponse)
            // 4. Check admin role and set user
            if (profileResponse.data.roles.includes('admin')) {
                dispatch(setUser(profileResponse?.data));
                navigate('/');
                toast({
                    variant: "success",
                    title: 'Succès',
                    description: 'Connexion réussie !'
                });
            } else {
                toast({
                    variant: "destructive",
                    title: 'Erreur',
                    description: "Accès refusé : vous n'êtes pas un administrateur.",
                    action: <button>Contactez le support</button>
                });
            }
        } catch (error) {
            console.error('Login failed:', error);
            toast({
                variant: "destructive",
                title: 'Erreur',
                description: 'Échec de la connexion. Vérifiez vos informations.'
            });
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <div className="h-screen  w-screen flex items-center justify-center bg-[#F8F7FB]">
            <div className="max-w-md w-full mx-4">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-gray-900 mb-2">Odon</h1>
                    <h2 className="text-xl text-gray-600">Administration</h2>
                </div>

                <div className="bg-white rounded-2xl shadow-lg p-8">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                                Adresse e-mail
                            </label>
                            <input
                                id="email"
                                type="text"
                                required
                                className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-[#FF6B6B] focus:border-transparent transition-colors"
                                placeholder="***********"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                                Mot de passe
                            </label>
                            <input
                                id="password"
                                type="password"
                                required
                                className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-[#FF6B6B] focus:border-transparent transition-colors"
                                placeholder="••••••••"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-[#FF6B6B] focus:ring-[#FF6B6B]"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700">
                                    Rester connecté
                                </label>
                            </div>
                            <a href="#" className="text-sm font-medium text-[#FF6B6B] hover:text-[#ff8585]">
                                Mot de passe oublié ?
                            </a>
                        </div>

                        <LoadingButton
                            type="submit"
                            isLoading={isLoading}
                            className="w-full"
                        >
                            <LockIcon className="h-5 w-5 mr-2" />
                            Se connecter
                        </LoadingButton>
                    </form>
                </div>

                <p className="mt-6 text-center text-sm text-gray-500">
                    Accès réservé aux administrateurs
                </p>
            </div>
        </div>
    );
};

export default AdminLogin;