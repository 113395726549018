// src/store/store.ts
import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import userReducer from './slices/userSlice'
import sidebarReducer from './slices/sidebarSlice'
import { apiSlice } from './slices/apiSlice'


export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    user: userReducer,
    sidebar: sidebarReducer,

  }, middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true }).concat(apiSlice.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch