import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Users, Store, Bell, ShoppingCart, BookOpen } from 'lucide-react';

const Navigation = () => {
  const location = useLocation();

  const navItems = [
    {
      label: 'Marchands',
      to: '/merchants',
      icon: Users
    },
    {
      label: 'Marchés',
      to: '/markets',
      icon: Store
    },
    {
      label: 'Publicités',
      to: '/ads',
      icon: Bell
    },
    {
      label: 'Commandes',
      to: '/commandes',
      icon: ShoppingCart
    },
    {
      label: 'Bon à savoir',
      to: '/good-to-know',
      icon: BookOpen
    }
  ];

  return (
    <nav className="space-y-2">
      {navItems.map((item) => {
        const Icon = item.icon;
        const isActive = location.pathname === item.to;
        
        return (
          <Link 
            key={item.to} 
            to={item.to}
            className="block" // Ensures the link takes full width
          >
            <Button
              variant="ghost"
              className={`w-full justify-start ${
                isActive ? 'bg-accent' : ''
              }`}
            >
              <Icon className="mr-2 h-4 w-4" />
              {item.label}
            </Button>
          </Link>
        );
      })}
    </nav>
  );
};

export default Navigation;