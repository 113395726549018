import React, { useState, useEffect, ImgHTMLAttributes } from 'react';
import { Skeleton } from './../components/ui/skeleton';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  width: number | string;
  height: number | string;
  className?: string;
  priority?: boolean;
  loading?: 'lazy' | 'eager';
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  quality?: number;
  placeholder?: 'blur' | 'skeleton' | 'none';
  onLoad?: React.ReactEventHandler<HTMLElement>;
  onError?: React.ReactEventHandler<HTMLElement>;
}

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  width,
  height,
  className = '',
  priority = false,
  loading = 'lazy',
  objectFit = 'cover',
  quality = 75,
  placeholder = 'blur',
  onLoad,
  onError,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(!priority);
  const [imgSrc, setImgSrc] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    // Fonction pour générer l'URL optimisée
    const getOptimizedSrc = (): string => {
      // Si on utilise un service d'optimisation d'images comme Cloudinary
      if (src.includes('cloudinary.com')) {
        const baseUrl = src.split('/upload/')[0];
        const imagePath = src.split('/upload/')[1];
        return `${baseUrl}/upload/c_scale,w_${width},q_${quality}/${imagePath}`;
      }
      
      // Si on utilise imgix
      if (src.includes('imgix.net')) {
        return `${src}?w=${width}&q=${quality}&auto=format`;
      }
      
    
      
      // Fallback sur l'image originale
      return src;
    };

    setImgSrc(getOptimizedSrc());
  }, [src, width, quality]);

  const handleLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setIsLoading(false);
    if (onLoad) onLoad(e);
  };

  const handleError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setError(true);
    setIsLoading(false);
    if (onError) onError(e);
  };

  // Style pour le conteneur
  const containerStyle: React.CSSProperties = {
    position: 'relative',
    width: width || '100%',
    height: height || 'auto',
    overflow: 'hidden',
  };

  // Style pour l'image
  const imgStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit,
    opacity: isLoading ? 0 : 1,
    transition: 'opacity 0.2s ease-in-out',
    ...props.style,
  };

  // Composant de fallback en cas d'erreur
  const ErrorFallback = () => (
    <div 
      className="flex items-center justify-center bg-gray-100 w-full h-full"
      style={{ minHeight: height }}
    >
      <svg
        className="w-10 h-10 text-gray-400"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
    </div>
  );

  // LQIP (Low Quality Image Placeholder)
  const BlurredPlaceholder = () => (
    <div
      className="absolute inset-0 blur-lg scale-110"
      style={{
        backgroundImage: `url(${src}?w=20)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  );

  if (error) {
    return <ErrorFallback />;
  }

  return (
    <div style={containerStyle} className={className}>
      {isLoading && placeholder === 'blur' && <BlurredPlaceholder />}
      {isLoading && placeholder === 'skeleton' && (
        <Skeleton className="w-full h-full absolute inset-0" />
      )}
      <img
        src={imgSrc}
        alt={alt}
        loading={priority ? 'eager' : loading}
        onLoad={handleLoad}
        onError={handleError}
        style={imgStyle}
        {...props}
      />
    </div>
  );
};

export default Image;
