import { RouterProvider } from "react-router-dom";
import router from "./router";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { checkAuthState } from "./store/slices/authSlice";
import { AppDispatch } from "./store";
import { LoadingScreen } from "./components/button/LoadingScreen";

export default function App() {
  const dispatch = useDispatch<AppDispatch>();
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPageLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);





  useEffect(() => {
    const initializeAuth = () => {
      dispatch(checkAuthState());
    };

    initializeAuth();
  }, [dispatch]);

  if (isPageLoading) {
    return <LoadingScreen />;
  }
  return <RouterProvider router={router} />;
}
