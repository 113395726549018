import React, { useState, useMemo } from 'react'
import { ChevronDown, Filter, MoreVertical } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"

export interface Column<T> {
  key: keyof T | string
  header: string
  render?: (item: T) => React.ReactNode
}

interface Action<T> {
  label: string
  icon?: React.ReactNode
  onClick: (item: T) => void
}

interface DataTableProps<T> {
  data: T[]
  columns: Column<T>[]
  actions?: Action<T>[]
  itemsPerPage?: number
  searchKeys?: (keyof T)[]
}

export function DataTable<T extends { id: string | number }>({
  data,
  columns,
  actions = [],
  itemsPerPage = 10,
  searchKeys = []
}: DataTableProps<T>) {
  const [sortColumn, setSortColumn] = useState<keyof T | string | null>(null)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState<Partial<Record<keyof T | string, string>>>({})
  const [search, setSearch] = useState('')
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)

  const handleSort = (column: keyof T | string) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  const filteredData = useMemo(() => {
    return data.filter(item => {
      const matchesFilters = Object.entries(filters).every(([key, value]) => {
        const itemValue = key.includes('.')
          ? key.split('.').reduce((obj: any, key) => obj && obj[key], item)
          : (item as any)[key]
        return String(itemValue).toLowerCase().includes(value.toLowerCase())
      })

      const matchesSearch = search === '' || searchKeys.some(key =>
        String((item as any)[key]).toLowerCase().includes(search.toLowerCase())
      )

      return matchesFilters && matchesSearch
    })
  }, [data, filters, search, searchKeys])

  const sortedData = useMemo(() => {
    if (sortColumn) {
      return [...filteredData].sort((a, b) => {
        let aValue = sortColumn.includes('.')
          ? sortColumn.split('.').reduce((obj: any, key) => obj && obj[key], a)
          : (a as any)[sortColumn]
        let bValue = sortColumn.includes('.')
          ? sortColumn.split('.').reduce((obj: any, key) => obj && obj[key], b)
          : (b as any)[sortColumn]

        if (typeof aValue === 'string') aValue = aValue.toLowerCase()
        if (typeof bValue === 'string') bValue = bValue.toLowerCase()

        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1
        return 0
      })
    }
    return filteredData
  }, [filteredData, sortColumn, sortDirection])

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage
    return sortedData.slice(startIndex, startIndex + itemsPerPage)
  }, [sortedData, currentPage, itemsPerPage])

  const pageCount = Math.ceil(sortedData.length / itemsPerPage)

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex flex-col sm:flex-row justify-between items-center">
        <div className="w-full sm:w-64 mb-2 sm:mb-0">
          <Input
            type="text"
            placeholder="Rechercher..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Dialog open={isFilterDialogOpen} onOpenChange={setIsFilterDialogOpen}>
          <DialogTrigger asChild>
            <Button variant="outline">
              <Filter className="mr-2 h-4 w-4" />
              Filtres avancés
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Filtres avancés</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              {columns.map((column) => (
                <div key={String(column.key)} className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor={String(column.key)} className="text-right">
                    {column.header}
                  </Label>
                  <Input
                    id={String(column.key)}
                    value={filters[column.key] || ''}
                    onChange={(e) => setFilters({ ...filters, [column.key]: e.target.value })}
                    className="col-span-3"
                  />
                </div>
              ))}
            </div>
            <Button onClick={() => setIsFilterDialogOpen(false)}>Appliquer les filtres</Button>
          </DialogContent>
        </Dialog>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-50">
            <tr>
              {columns.map((column) => (
                <th
                  key={String(column.key)}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort(column.key)}
                >
                  <div className="flex items-center">
                    {column.header}
                    {sortColumn === column.key && (
                      <ChevronDown
                        className={`ml-1 h-4 w-4 transform ${sortDirection === 'desc' ? 'rotate-180' : ''
                          }`}
                      />
                    )}
                  </div>
                </th>
              ))}
              {actions.length > 0 && (
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {paginatedData.map((item) => (
              <tr key={item.id} className="hover:bg-gray-50">
                {columns.map((column) => (
                  <td key={`${item.id}-${String(column.key)}`} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {column.render ? column.render(item) : String((item as any)[column.key])}
                  </td>
                ))}
                {actions.length > 0 && (
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                          <span className="sr-only">Open menu</span>
                          <MoreVertical className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        {actions.map((action, index) => (
                          <DropdownMenuItem key={index} onClick={() => action.onClick(item)}>
                            {action.icon && <span className="mr-2">{action.icon}</span>}
                            <span>{action.label}</span>
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
        <div className="mb-2 sm:mb-0 text-sm text-gray-700">
          Affichage de {(currentPage - 1) * itemsPerPage + 1} à {Math.min(currentPage * itemsPerPage, sortedData.length)} sur {sortedData.length} entrées
        </div>
        <div className="flex items-center">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
            disabled={currentPage === 1}
          >
            Précédent
          </Button>
          <span className="mx-2">
            Page {currentPage} sur {pageCount}
          </span>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage(page => Math.min(page + 1, pageCount))}
            disabled={currentPage === pageCount}
          >
            Suivant
          </Button>
        </div>
      </div>
    </div>
  )
}

