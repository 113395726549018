import React from 'react'
import { useActivateMerchantMutation, useGetMerchantsQuery } from '../../store/slices/apiSlice'
import { LoadingSpinner } from '../../components/button/LoadingSpinner';
import { DataTable } from '../../components/DataTable';
import { Avatar, AvatarFallback, AvatarImage } from '../../components/ui/avatar';
import { Check, Eye, LockOpenIcon, MapPin, Phone, Trash, User } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import { Card, CardContent } from '../../components/ui/card';

interface Merchant {
  id: string
  etablissement: string
  telephone: string
  description: string
  content: string
  adresse: string
  pays: string
  latitude: number
  longitude: number
  type: string
  photo: string
  user: {
    nom: string
    prenom: string
  }
}

const MerchantTable = () => {
  const [selectedCommerce, setSelectedCommerce] = React.useState<CommerceData | null>(null)


  const { data: merchants, isLoading, isError, refetch } = useGetMerchantsQuery();

  // Mutation pour changer le statut
  const [activateMerchant, { isLoading: isActivating, isSuccess: isActivated }] = useActivateMerchantMutation();

  const handleToggleStatus = async (merchant: Merchant) => {
    try {

      await activateMerchant(merchant).unwrap();
      refetch();
      alert(`${merchant.name} est maintenant actif.`);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut :', error);
    }
  };
  //console.log(merchants)

  if (isLoading) {
    return <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <LoadingSpinner />
    </div>
  }

  if (isError) {
    return <div>Error</div>
  }


  const columns = [
    {
      key: 'photo',
      header: 'Profil',
      render: (item: any) => (
        <Avatar>
          {item.photo ? (
            <AvatarImage src={item.photo} alt={item.etablissement} />
          ) : (
            <AvatarFallback>{item.etablissement[0]}</AvatarFallback>
          )}
        </Avatar>
      ),
    },
    { key: 'etablissement', header: 'Établissement' },
    { key: 'telephone', header: 'Téléphone' },
    { key: 'adresse', header: 'Pays' },


    {
      key: 'owner.fullname',
      header: 'Propriétaire',
      render: (item: any) => `${item.user.prenom} ${item.user.nom}`,
    }, {
      key: 'status', header: 'Statut',

      render: (item: any) => {
        if (item.status === "activate") {
          return <div className="text-green-500 font-semibold">Actif</div>
        }
        return <div className="text-red-500 font-semibold">{item.status}</div>

      }
    },

  ];

  const actions = [
    {
      label: 'Voir détails',
      icon: <Eye className="h-4 w-4" />,
      onClick: (item: Merchant) => setSelectedCommerce(item)
    },
    {
      label: 'Supprimer',
      icon: <Trash className="h-4 w-4" />,
      onClick: (item: Merchant) => console.log(`Supprimer ${item.etablissement}`)
    },
    {
      label: 'Activer',
      icon: <Check className="h-4 w-4" />,
      onClick: (item: Merchant) => handleToggleStatus(item),
      disabled: selectedCommerce?.status === "ACTIVE"
    }
    ,
    {
      label: 'Désactiver',
      icon: <LockOpenIcon className="h-4 w-4" />,
      onClick: (item: Merchant) => console.log(`Désactiver ${item.etablissement}`),
      disabled: selectedCommerce?.status === "INACTIVE"
    }
  ]

  return (
    <div className="min-h-screen w-auto bg-gray-100 py-12">
      <h1 className="text-3xl font-bold text-center mb-8">Liste des Commerces</h1>
      <h3 className="text-center text-gray-500">
        {isActivating ? 'Activation...' : isActivated ? 'Activation effectuée' : ''}
      </h3>

      <DataTable
        data={merchants}
        columns={columns}
        actions={actions}
        itemsPerPage={5}
        searchKeys={['etablissement', 'telephone', 'pays', 'user.nom', 'user.prenom']}
      />

      <Dialog open={!!selectedCommerce} onOpenChange={() => setSelectedCommerce(null)}>
        <DialogContent className="sm:max-w-[625px]">
          <DialogHeader>
            <DialogTitle>{selectedCommerce?.etablissement}</DialogTitle>
          </DialogHeader>
          {selectedCommerce && (
            <Card>
              <CardContent className="pt-6">
                <div className="flex items-center space-x-4 mb-4">
                  <Avatar className="w-20 h-20">
                    <AvatarImage src={selectedCommerce.photo} alt={selectedCommerce.etablissement} />
                    <AvatarFallback>{selectedCommerce.etablissement[0]}</AvatarFallback>
                  </Avatar>
                  <div>
                    <h3 className="text-xl font-semibold">{selectedCommerce.etablissement}</h3>
                    <p className="text-gray-500">{selectedCommerce.description}</p>
                  </div>
                </div>
                <div className="grid gap-4">
                  <div className="flex items-center space-x-2">
                    <User className="text-gray-500" />
                    <span>{selectedCommerce.user.nom} {selectedCommerce.user.prenom}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Phone className="text-gray-500" />
                    <span>{selectedCommerce.telephone}</span>
                  </div>
                  <div className="flex items-start space-x-2">
                    <MapPin className="text-gray-500 mt-1" />
                    <span>{selectedCommerce.adresse}, {selectedCommerce.pays}</span>
                  </div>
                  <div className="mt-4">
                    <h4 className="font-semibold mb-2">À propos</h4>
                    <div dangerouslySetInnerHTML={{ __html: selectedCommerce.content }} />
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MerchantTable