import React, { useState } from 'react'

import { Bell, Menu, ShoppingCart, Store, Users, ChevronRight, X, BookOpen } from 'lucide-react'
import { Button } from "./../components/ui/button"
import { useAppDispatch, useAppSelector } from '../lib/hook';
import { toggleSidebar } from '../store/slices/sidebarSlice';
import Navigation from './Navigation';







const Sidebar = () => {

    const isOpen = useAppSelector(state => state.sidebar.isOpen);
    const dispatch = useAppDispatch();

    const handleToggle = (): void => {
        dispatch(toggleSidebar());
    };


    return (
        <>
            <div
                className={`fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity duration-200 md:hidden ${isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
                    }`}
                onClick={handleToggle}
            />

            <aside className={`bg-white w-64 min-h-screen p-4 z-50 transition-transform duration-200 ease-in-out lg:relative lg:translate-x-0 ${isOpen ? '' : 'hidden'}`}>
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-semibold text-gray-800">Odon Panel</h2>
                    <Button variant="ghost" size="icon" onClick={handleToggle}>
                        <Menu className="h-6 w-6" />
                    </Button>
                </div>
                <Navigation />

                {/* 
                <nav>

                    <Button variant="ghost" className="w-full justify-start mb-2">
                        <Users className="mr-2 h-4 w-4" /> Marchands
                    </Button>
                    <Button variant="ghost" className="w-full justify-start mb-2">
                        <Store className="mr-2 h-4 w-4" /> Marchés
                    </Button>
                    <Button variant="ghost" className="w-full justify-start mb-2">
                        <Bell className="mr-2 h-4 w-4" /> Publicités
                    </Button>
                    <Button variant="ghost" className="w-full justify-start mb-2">
                        <ShoppingCart className="mr-2 h-4 w-4" /> Commandes
                    </Button>
                    <Button variant="ghost" className="w-full justify-start">
                        <BookOpen className="mr-2 h-4 w-4" /> Bon à savoir
                    </Button>

                </nav>
 */}
            </aside>

            {/* Toggle button for mobile */}
            <button
                onClick={handleToggle}
                className="fixed left-4 top-4 z-40 p-2 bg-white rounded-md shadow-md md:hidden"
                aria-label="Toggle sidebar"
            >
                {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>

            {/* Toggle button for desktop (when sidebar is collapsed) */}
            <button
                onClick={handleToggle}
                className={`fixed left-4 top-4 z-40 p-2 bg-white rounded-md shadow-md hidden md:block ${isOpen ? "lg:hidden" : ""
                    }`}
                aria-label="Expand sidebar"
            >
                <ChevronRight className="h-6 w-6" />
            </button>
        </>
    )
}

export default Sidebar
