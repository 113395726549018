import React from 'react';
import { LoadingSpinner } from './LoadingSpinner';

export const LoadingScreen: React.FC = () => {
  return (
    <div className="fixed inset-0 bg-[#F8F7FB] flex flex-col items-center justify-center z-50">
      <LoadingSpinner size="lg" className="mb-4" />
      <h2 className="text-xl font-medium text-gray-700">Chargement...</h2>
    </div>
  );
};

