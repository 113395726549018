import { useState } from "react";
import Sidebar from "./Sidebar"
import { Outlet } from 'react-router-dom';
import { useAppSelector } from "../lib/hook";
import Header from "./Header";



const Layout = () => {
    const isOpen = useAppSelector(state => state.sidebar.isOpen);


    return (
        <>
            <div className="h-screen  w-screen flex  bg-gray-100">
                {/* Sidebar */}
                <Sidebar />
                {/* Main Content */}
                <main className={` flex-1 p-6 overflow-auto ${isOpen ? '' : 'sm:ml-10'}`}>
                    <Header />
                    <Outlet />
                </main>
            </div >

        </>
    )
}

export default Layout