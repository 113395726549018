
import { AlertCircle } from 'lucide-react'

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
      <Card className="w-full max-w-md">
        <CardHeader className="text-center">
          <div className="flex justify-center mb-4">
            <AlertCircle className="h-16 w-16 text-red-500" />
          </div>
          <CardTitle className="text-3xl font-bold">Erreur 404</CardTitle>
          <CardDescription>Oups ! La page que vous recherchez n'existe pas.</CardDescription>
        </CardHeader>
        <CardContent className="text-center">
          <p className="text-gray-600 dark:text-gray-400">
            Il semble que vous ayez suivi un lien cassé ou entré une URL qui n'existe pas sur ce site.
          </p>
        </CardContent>
        <CardFooter className="flex flex-col space-y-4">
          <Button asChild className="w-full">
            <Link to="/">
              Retour à l'accueil
            </Link>
          </Button>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            Liens utiles :
            <ul className="mt-2 space-y-1">
              <li>
                <Link to="/markets" className="text-blue-500 hover:underline">
                  Liste des marchés
                </Link>
              </li>
              <li>
                <Link to="/bon-a-savoir" className="text-blue-500 hover:underline">
                  Bon à savoir
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-blue-500 hover:underline">
                  Contactez-nous
                </Link>
              </li>
            </ul>
          </div>
        </CardFooter>
      </Card>
    </div>
  )
}