
import { SetStateAction, useState} from 'react'
import {BookOpen, Plus, Search} from 'lucide-react'
import {Button} from "@/components/ui/button"
import {Input} from "@/components/ui/input"
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@/components/ui/card"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/components/ui/dialog"
import {Label} from "@/components/ui/label"
import {Textarea} from "@/components/ui/textarea"
import {useLocation} from 'react-router-dom'

interface BonASavoirItem {
  id: string;
  name: string;
  image: string;
  description: string;
  updated_at: string;
}

const ITEMS_PER_PAGE = 9;

export default function GoodToKnow() {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [bonASavoirItems, setBonASavoirItems] = useState<BonASavoirItem[]>([
    {
      id: "9d69905f-8a65-4fea-8f00-97e7fe22f174",
      name: "feuille d'eucalyptus séché bienfaits",
      image: "https://prodapi.odon-afrik.com/storage/images/gtk/HruUs6DC4aTSn3wWoms0aaWiKNGw72ukriVy6sEg.png",
      description: "Les feuilles d'eucalyptus séchées sont réputées pour leurs nombreux bienfaits, en particulier grâce à leurs huiles essentielles concentrées, dont l'eucalyptol. Elles sont traditionnellement utilisées pour soulager les symptômes des affections respiratoires, comme la toux, le rhume et la sinusite...",
      updated_at: "5 jours"
    },
    {
      id: "9d69927e-6459-4f80-a35c-7232d0b468d0",
      name: "le moringa",
      image: "https://prodapi.odon-afrik.com/storage/images/gtk/SzdQLLkDQQgAC2I7HuPHAcZvfJpfNP6S0fijLk5o.jpg",
      description: "Le moringa, souvent appelé \"arbre miracle\", est reconnu pour ses nombreuses vertus nutritionnelles et thérapeutiques. Ses feuilles, graines, et racines sont utilisées dans plusieurs systèmes de médecine traditionnelle...",
      updated_at: "5 jours"
    },
    {
      id: "9d7534ea-9526-42ef-bf3f-84aa14d9cd85",
      name: "La feuille de laurier",
      image: "https://prodapi.odon-afrik.com/storage/images/gtk/K1SWN3sSCxyo7dM77eVHNyPX6OOsiCcWtxk3tG4l.jpg",
      description: "La feuille de laurier, issue du laurier-sauce Laurus nobilis, est utilisée depuis longtemps pour ses nombreux bienfaits. Elle aide à la digestion en réduisant les ballonnements et les gaz, ce qui en fait un allié pour le confort digestif...",
      updated_at: "2 minutes"
    }
  ])

  const [newItem, setNewItem] = useState<Partial<BonASavoirItem>>({
    name: '',
    image: '',
    description: ''
  })

  const filteredItems = bonASavoirItems.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.description.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const totalPages = Math.ceil(filteredItems.length / ITEMS_PER_PAGE)
  const paginatedItems = filteredItems.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE
  )

  const handleAddNewItem = () => {
    if (newItem.name && newItem.image && newItem.description) {
      const newItemWithId: BonASavoirItem = {
        ...newItem as BonASavoirItem,
        id: Date.now().toString(),
        updated_at: 'À l\'instant'
      }
      setBonASavoirItems([newItemWithId, ...bonASavoirItems])
      setNewItem({name: '', image: '', description: ''})
    }
  }

  return (
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-semibold text-gray-800 mb-6 flex items-center">
          <BookOpen className="mr-2 h-6 w-6"/>
          {location.pathname}
        </h1>

        <div className="flex justify-between items-center mb-6">
          <div className="relative">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground"/>
            <Input
                type="search"
                placeholder="Rechercher..."
                value={searchTerm}
                onChange={(e: { target: { value: SetStateAction<string> } }) => {
              setSearchTerm(e.target.value)
              setCurrentPage(1)  // Reset to first page on new search
            }}
            className="pl-8 w-64"
          />
        </div>
        <Dialog>
          <DialogTrigger asChild>
            <Button>
              <Plus className="mr-2 h-4 w-4" />
              Ajouter un élément
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Ajouter un nouvel élément</DialogTitle>
              <DialogDescription>
                Remplissez les informations pour ajouter un nouvel élément "Bon à savoir".
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Nom
                </Label>
                <Input
                  id="name"
                  value={newItem.name}
                  onChange={(e: { target: { value: never } }) => setNewItem({...newItem, name: e.target.value})}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="image" className="text-right">
                  URL de l'image
                </Label>
                <Input
                  id="image"
                  value={newItem.image}
                  onChange={(e) => setNewItem({...newItem, image: e.target.value})}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="description" className="text-right">
                  Description
                </Label>
                <Textarea
                  id="description"
                  value={newItem.description}
                  onChange={(e) => setNewItem({...newItem, description: e.target.value})}
                  className="col-span-3"
                />
              </div>
            </div>
            <DialogFooter>
              <Button onClick={handleAddNewItem}>Ajouter</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {paginatedItems.map((item) => (
          <Card key={item.id}>
            <CardHeader>
              <CardTitle>{item.name}</CardTitle>
              <CardDescription>Mis à jour {item.updated_at}</CardDescription>
            </CardHeader>
            <CardContent>
              <img src={item.image} alt={item.name} className="w-full h-48 object-cover rounded-md mb-4" />
              <p className="text-sm text-gray-600">{item.description.substring(0, 150)}...</p>
            </CardContent>
            <CardFooter>
              <Button variant="outline" className="w-full">Lire plus</Button>
            </CardFooter>
          </Card>
        ))}
      </div>

      {filteredItems.length === 0 && (
        <p className="text-gray-600 text-center mt-6">Aucun résultat trouvé pour votre recherche.</p>
      )}

      {totalPages > 1 && (
        <div className="flex justify-center mt-8">
          <nav className="flex items-center space-x-2">
            <Button
              variant="outline"
              onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
              disabled={currentPage === 1}
            >
              Précédent
            </Button>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <Button
                key={page}
                variant={currentPage === page ? "default" : "outline"}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </Button>
            ))}
            <Button
              variant="outline"
              onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
              disabled={currentPage === totalPages}
            >
              Suivant
            </Button>
          </nav>
        </div>
      )}
    </div>
  )
}