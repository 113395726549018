import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

// Types pour l'authentification et les données des marchands
interface AuthResponse {
  data: any;
  token: string;
  user: {
    data(data: any): { payload: any; type: "user/setUser"; };
    roles: any;
    id: string;
    email: string;
    name: string;
  };
}

interface LoginCredentials {
  telephone: string;
  password: string;
}

interface Merchant {
  id: string;
  name: string;
}


type ApiResponse<T> = {
  status: string;
  data: T;
};
// Création de l'API avec Redux Toolkit Query
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://prodapi.odon-afrik.com/api/',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as any).auth?.token;
      console.log(token)
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Auth', 'Merchant'],
  endpoints: (builder) => ({
    // Endpoint pour la connexion (authentification)
    login: builder.mutation<AuthResponse, LoginCredentials>({
      query: (credentials) => ({
        url: 'admin/hyper/login',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['Auth'],
    }),

    // Endpoint pour obtenir les informations utilisateur après connexion
    getProfile: builder.query<AuthResponse['user'], void>({
      query: () => 'me',
      providesTags: ['Auth'],
    }),

    // Endpoint pour obtenir la liste des marchands
    getMerchants: builder.query<Merchant[], void>({
      query: () => 'admin/merchants',
      transformResponse: (response: ApiResponse<Merchant[]>) => response.data,
      providesTags: (result) =>

        result
          ? [

            ...result?.map(({ id }) => ({ type: 'Merchant' as const, id })),
            { type: 'Merchant', id: 'LIST' },
          ]
          : [{ type: 'Merchant', id: 'LIST' }],
    }),

    // Endpoint pour ajouter un marchand
    addMerchant: builder.mutation<Merchant, Merchant>({
      query: (merchant) => ({
        url: 'merchants',
        method: 'POST',
        body: merchant,
      }),
      invalidatesTags: [{ type: 'Merchant', id: 'LIST' }],
    }),

    activateMerchant: builder.mutation({
      // Configuration de la mutation
      query: (merchant) => ({
        url: `admin/active/merchant/${merchant.id}`, // URL avec l'ID du marchand
        method: 'POST', // Méthode HTTP utilisée
        body: merchant, // Données envoyées dans le corps de la requête
      }),
      // Invalidations pour forcer une actualisation
      invalidatesTags: [{ type: 'Merchant', id: 'LIST' }],
    }),
  }),
});

// Exportation des hooks générés automatiquement
export const {
  useLoginMutation,
  useGetProfileQuery,
  useGetMerchantsQuery,
  useAddMerchantMutation,
  useActivateMerchantMutation,
} = apiSlice;
