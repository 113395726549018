import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice';
import { AppDispatch } from '..';
import { setUser } from './userSlice';

interface AuthState {
  token: string | null;
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  token: localStorage.getItem('token'),
  isAuthenticated: !!localStorage.getItem('token'),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token;
      state.isAuthenticated = true;
      localStorage.setItem('token', action.payload.token);
    },
    clearAuth: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token;
        state.isAuthenticated = true;
        localStorage.setItem('token', payload.token);
      }
    );
  },
});

export const { setAuth, clearAuth } = authSlice.actions;
export default authSlice.reducer;
// authSlice.ts
// Middleware pour vérifier l'état de l'authentification
export const checkAuthState = () => async (dispatch: AppDispatch) => {
  const token = localStorage.getItem('token');

  if (token) {
    try {
      // Mettre à jour le token dans l'état auth
      dispatch(setAuth({ token }));

      // Déclencher la requête pour récupérer le profil utilisateur
      const result = await dispatch(
        apiSlice.endpoints.getProfile.initiate()
      ).unwrap();

      // Vérifier si l'utilisateur a le rôle 'admin'
      if (result?.data?.roles?.includes('admin')) {
        dispatch(setUser(result.data)); // Mettre à jour l'utilisateur dans Redux
      } else {
        throw new Error("Access denied: User is not an admin");
      }
    } catch (error) {
      console.error("Erreur lors de la vérification de l'authentification :", error);
      dispatch(clearAuth());
      localStorage.removeItem('token');
    }
  } else {
    dispatch(clearAuth());
  }
};
