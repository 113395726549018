import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice';

interface User {
  id: string;
  email: string;
  name: string;
  roles: any;
}

interface UserState {
  profile: User | null;
}

const initialState: UserState = {
  profile: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.profile = action.payload;
    },
    clearUser: (state) => {
      state.profile = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getProfile.matchFulfilled,
      (state, { payload }) => {
        state.profile = payload?.data;
      }
    );
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;