import { createBrowserRouter, Navigate } from 'react-router-dom';
import Layout from '../layouts';
import NotFound from '../views/NotFound';
import Home from '../views/Home';
import GoodToKnow from '../views/goodToKnow/GoodToKnow';
import MarketTable from '../views/markets/MarketTable';
import AddMarket from '../views/markets/AddMarket';
import MerchantTable from '../views/merchants/MerchantTable';
import Notifications from '../views/notifications/Notifications';
import CreateNotification from '../views/notifications/CreateNotification';
import AdsTable from '../views/ads/AdsTable';
import CreateAd from '../views/ads/CreateAd';
import UserTable from '../views/users/UserTable';
import AddUser from '../views/users/AddUser';
import AddGoodToKnow from '../views/goodToKnow/AddGoodToKnow';
import AddMerchant from '../views/merchants/AddMerchant';
import AdminLogin from '../views/auth/login';
import { useSelector } from 'react-redux';
import { RootState } from '../store';


// Garde pour protéger les routes nécessitant une authentification
const ProtectedRoute = ({ element }: { element: JSX.Element }) => {
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  const { profile } = useSelector((state: RootState) => state.user);
  if (!isAuthenticated || !profile?.roles?.includes('admin')) {
    return <Navigate to="/login" replace />;
  }

  console.log(!isAuthenticated || !profile?.roles?.includes('admin'))

  return element;
};

// Route pour les pages sans layout
const PublicRoute = ({ element }: { element: JSX.Element }) => element;

// Définition des routes
const Router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />, // Layout par défaut pour les routes enfants
    errorElement: <NotFound />, // Gestion des erreurs
    children: [
      {
        path: '/',
        element: <ProtectedRoute element={<Home />} />, // Protégé par l'authentification
      },
      {
        path: '/users',
        element: <ProtectedRoute element={<UserTable />} />,
      },
      {
        path: '/add-user',
        element: <ProtectedRoute element={<AddUser />} />,
      },
      {
        path: '/good-to-know',
        element: <ProtectedRoute element={<GoodToKnow />} />,
      },
      {
        path: '/add-good-to-know',
        element: <ProtectedRoute element={<AddGoodToKnow />} />,
      },
      {
        path: '/markets',
        element: <ProtectedRoute element={<MarketTable />} />,
      },
      {
        path: '/add-market',
        element: <ProtectedRoute element={<AddMarket />} />,
      },
      {
        path: '/merchants',
        element: <ProtectedRoute element={<MerchantTable />} />,
      },
      {
        path: '/add-merchant',
        element: <ProtectedRoute element={<AddMerchant />} />,
      },
      {
        path: '/notifications',
        element: <ProtectedRoute element={<Notifications />} />,
      },
      {
        path: '/create-notification',
        element: <ProtectedRoute element={<CreateNotification />} />,
      },
      {
        path: '/ads',
        element: <ProtectedRoute element={<AdsTable />} />,
      },
      {
        path: '/add-ad',
        element: <ProtectedRoute element={<CreateAd />} />,
      },
    ],
  },
  {
    path: '/login',
    element: <PublicRoute element={<AdminLogin />} />, // Pas de layout sur cette page
  },
  {
    path: '*',
    element: <PublicRoute element={<NotFound />} />, // Page 404 sans layout
  },
]);

export default Router;