import React from 'react';
import { LoadingSpinner } from './LoadingSpinner';

interface LoadingButtonProps {
  isLoading: boolean;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  type?: 'button' | 'submit';
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  isLoading,
  children,
  className = '',
  onClick,
  type = 'button'
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={isLoading}
      className={`relative flex items-center justify-center px-4 py-3 rounded-xl bg-[#FF6B6B] text-white font-medium hover:bg-[#ff8585] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF6B6B] transition-colors disabled:opacity-70 ${className}`}
    >
      {isLoading ? (
        <>
          <LoadingSpinner size="sm" className="mr-2" />
          Chargement...
        </>
      ) : (
        children
      )}
    </button>
  );
};

